@import "../../../../scss/variables";

.SwInspectionReportAnlalytics {
  .grid_heading_style {
    font-family: $font-lato;
    font-size: $font-size-22;
    font-weight: 700;
    text-align: left;
  }
}
.MapOverride {
  height: 90vh;
  width: 100%;
  .leaflet-container {
    height: 100%;
    width: 100%;
    border-radius: 2px;
  }
}
.awesome-marker i {
  font-size: 18px;
  margin-top: 8px;
}

.SwSummaryReportContainer{
  .heading_style_SwRePort{
    font-family: $font-lato;
font-size: $font-size-20;
font-weight: 700;
text-align: left;
 }
}

.SwSummaryReportContainer {
  .heading_style_SwRePort {
    font-family: $font-lato;
    font-size: $font-size-20;
    font-weight: 700;
    text-align: left;
  }
}
.popupMapstyleConatiner {
  .popupMapstyle {
    font-size: $font-size-14;
    color: $black-color;
    font-family: $font-lato;
    font-weight: 600;
  }
}

body {
  &.dark-mode {
    .SwInspectionReportAnlalytics {
      .grid_heading_style {
        color: $dark-primary-text-color;
      }
    }
  }
}
